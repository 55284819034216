import React, { Component } from "react";
import PropTypes from "prop-types";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";

class Map extends Component {
  render() {
    return (
      <GoogleMap
        defaultZoom={18}
        defaultCenter={{ lat: this.props.latitude, lng: this.props.longitude }}
      >
        <Marker
          position={{ lat: this.props.latitude, lng: this.props.longitude }}
        />
      </GoogleMap>
    );
  }
}

Map.propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
};

export default withScriptjs(withGoogleMap(Map));
