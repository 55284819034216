import React from "react";
import ImageGallery from "react-image-gallery";

import "react-image-gallery/styles/css/image-gallery.css";

import "./styles.scss";

export const GalleryTemplate = props => {
  return (
    <section className="gallery contentSection">
      <h3>
        Bilder
      </h3>
      <ImageGallery
        items={props.images}
        showThumbnails={false}
        showFullscreenButton={false}
        showPlayButton={false}
      />
    </section>
  );
};

export const Gallery = props => {
  return <GalleryTemplate {...props} />;
};
