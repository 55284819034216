import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import Helmet from "react-helmet";
import ReactMarkdown from "react-markdown";

import HTMLContent from "../components/Content";
import Layout from "../components/Layout";
import Map from "../components/Map";
import "../styles/home.scss";
import { Gallery } from "../components/Gallery";

export const HomePageTemplate = ({ home, page }) => {
  const latitude = 48.204106;
  const longitude = 16.368086;
  return (
    <>
      <section className="header">
        <div className="header-container  container">
          <h3 className="header-tagline">{home.title}</h3>
          <div className="quote">
            <h4 className="quoteContent">{home.quote.content}</h4>
            <div className="quoteAuthor">{home.quote.author}</div>
          </div>
        </div>
      </section>
      <section className="probetrainingWrapper">
        <section className="probetraining contentSection">
          <h3>{home.probetraining.title}</h3>
          <p>
            {home.probetraining.description} <Link to={home.probetraining.info}>Mehr erfahren</Link>
          </p>
        </section>
      </section>
      <section className="news contentSection">
        <h3>News</h3>
        <ul>
          {home.news &&
            home.news.map(item => (
              <li key={item.title}>
                <h4>{item.title}</h4>
                <div className="newsDate">{item.date || <>&nbsp;</>}</div>
                <p>
                  {item.description} {item.info && <a href={item.info}>Info</a>}
                </p>
              </li>
            ))}
        </ul>
      </section>

      <section className="summaries contentSection">
        {page && page.bodyIsMarkdown ? (
          <ReactMarkdown source={page.html} />
        ) : (
          <HTMLContent content={page.html} />
        )}
      </section>

      <Gallery images={home.gallery} />
      {/* <section className="mapWrapper contentSection">
        <Map
          isMarkerShown
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCzsMhtww6g6l3ya2UlEfAdarZQaclCd0M&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          latitude={latitude}
          longitude={longitude}
        />
      </section> */}
    </>
  );
};

class HomePage extends React.Component {
  render() {
    const { data: { footerData, navbarData, homePageData } } = this.props;
    const page = homePageData.edges[0].node;
    const { frontmatter: home } = page;

    const {
      seo: { title: seoTitle, description: seoDescription, browserTitle }
    } = home;
    return (
      <Layout footerData={footerData} navbarData={navbarData}>
        <Helmet>
          <meta name="title" content={seoTitle} />
          <meta name="description" content={seoDescription} />
          <title>{browserTitle}</title>
        </Helmet>
        <HomePageTemplate home={home} page={{ ...page, bodyIsMarkdown: false }} />
      </Layout>
    );
  }
}

HomePage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export default HomePage;

export const pageQuery = graphql`
  query HomePageQuery {
    homePageData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "home-page" } } }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            quote {
              content
              author
            }
            probetraining {
              title
              description
              info
            }
            news {
              title
              date
              description
              info
            }
            gallery {
              original: image
            }
            seo {
              browserTitle
              title
              description
            }
          }
        }
      }
    }
    ...LayoutFragment
  }
`;
